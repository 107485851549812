<template>
  <div class="home">
    <div id="carousel" class="carousel slide" data-interval="10000" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="slide" :style="{'background-image': 'url(' + require('../assets/slide1.jpg') + ')'}"></div>
        </div>
        <div class="carousel-item">
          <div class="slide" :style="{'background-image': 'url(' + require('../assets/slide2.jpg') + ')'}"></div>
        </div>
        <div class="carousel-item">
          <div class="slide" :style="{'background-image': 'url(' + require('../assets/slide3.jpg') + ')'}"></div>
        </div>
      </div>
    </div>
    <div class="voileGris">
      <img class="logo" src="../assets/logo.png" alt="">
      <img class="stars" src="../assets/etoiles.png" alt="">
      <h1>L'Entre Deux Phares</h1>
      <a href="#ancre"><i class="fas fa-chevron-circle-down"></i></a>
    </div>

    <div id="ancre"></div>

    <div class="container text-center presentations">
      <div v-if="$store.state.lang == 'fr'">
        <h2>Découvrez Port-en-Bessin : Un Port de Pêche Authentique au Cœur des Plages du Débarquement en Normandie</h2>
          <p>
          Situé au cœur des plages du débarquement en Normandie, Port-en-Bessin est un charmant port de pêche artisanale et de plaisance dans le Calvados. Réputé pour ses délicieuses coquilles Saint-Jacques, ce village offre une situation géographique exceptionnelle :
          <br>
          - À seulement 10 minutes d’Arromanches et de son célèbre port artificiel<br>
          - À 10 minutes d’Omaha Beach, site historique du D-Day<br>
          - À 10 minutes de Bayeux, une ville médiévale au riche patrimoine historique<br>
          - À 30 minutes de Caen<br>
          - À 1h30 du Mont-Saint-Michel, l'un des sites les plus visités de France<br>
          - À 3h de Paris<br>
        </p>

        <p style="font-weight: bold;">
          Votre gîte avec vue sur la mer et les marées se trouve directement en front de mer et sur l’avant-port. Une ambiance cocooning vous attend pour vos prochaines vacances en Normandie !
        </p>

        <p>
          À marée basse, profitez d’une balade sur la petite plage devant l’immeuble. Ensuite, rendez-vous au marché aux poissons situé à seulement une minute à pied pour acheter votre poisson frais et vos crustacés.
        </p>

        <p>
          Ne manquez pas le marché hebdomadaire chaque dimanche matin et les feux d’artifice chaque vendredi soir en juillet et août à Port-en-Bessin.
        </p>

      </div>

      <div v-if="$store.state.lang == 'us'">
        <h2>Discover Port-en-Bessin: An Authentic Fishing Port in the Heart of the Normandy D-Day Beaches</h2>
          <p>
            Nestled in the heart of the Normandy D-Day beaches, Port-en-Bessin is a charming artisanal fishing and pleasure port in Calvados. Renowned for its delicious scallops, this village boasts an exceptional geographical location:
            <br>
            - Just 10 minutes from Arromanches and its famous artificial harbor<br>
            - 10 minutes from Omaha Beach, a historic D-Day site<br>
            - 10 minutes from Bayeux, a medieval town rich in historical heritage<br>
            - 30 minutes from Caen<br>
            - 1.5 hours from Mont-Saint-Michel, one of France's most visited sites<br>
            - 3 hours from Paris<br>
          </p>

          <p style="font-weight: bold;">
            Your seaside cottage with views of the sea and tides is located directly on the seafront and the harbor. A cozy atmosphere awaits you for your next vacation in Normandy!
          </p>

          <p>
            At low tide, enjoy a stroll on the small beach in front of the building. Then, head to the fish market, just a minute's walk away, to buy fresh fish and seafood.
          </p>

          <p>
            Don't miss the weekly market every Sunday morning and the fireworks every Friday evening in July and August in Port-en-Bessin.
          </p>
      </div>
    </div>

    <!--<div class="warn">
      <h3 v-if="$store.state.lang == 'fr'">Annonce !</h3>
      <h3 v-if="$store.state.lang == 'us'">Announcement !</h3>
      <hr>
      <p v-if="$store.state.lang == 'fr'">Notre nouvelle maison de charme "La maison d'Harold" accueillera ses premiers clients à partir du 15 juillet 2022.</p>
      <p v-if="$store.state.lang == 'us'">Our new charming house "La maison d'Harold" will welcome its first customers from July 15, 2022.</p>
    </div>-->

    <section class="listeGite">

      <div class="ligne">
        <div class="case">
          <img src="../assets/apt5/gris.jpg" alt="">
        </div>
        <div class="case">
          <div class="conteneur">
            <div class="gite">
              <h2>Les Pieds Dans l'Eau</h2>
              <p class="prix"><span v-if="$store.state.lang == 'fr'">À partir de 93€/nuit</span><span v-if="$store.state.lang == 'us'">From 93€/night</span></p>
              <p class="description">
                <span v-if="$store.state.lang == 'fr'">Au cœur des plages du débarquement, nous vous proposons un appartement de charme dans un ancien immeuble de pêcheur situé front de mer. </span><span v-if="$store.state.lang == 'us'">At the heart of the landing beaches, we offer a charming apartment in an old building of fishermen located seafront.</span> <br><br>
              </p>
            </div>
            <router-link to="/lespiedsdansleau" class="btn btn-perso"><span v-if="$store.state.lang == 'fr'">Découvrir !</span><span v-if="$store.state.lang == 'us'">Discover !</span></router-link>
          </div>
        </div>
      </div>

      <div class="ligne inverse">
        <div class="case">
          <div class="conteneur">
            <div class="gite">
              <h2>Sous Les Embruns</h2>
              <p class="prix"><span v-if="$store.state.lang == 'fr'">À partir de 93€/nuit</span><span v-if="$store.state.lang == 'us'">From 93€/night</span></p>
              <p class="description">
                <span v-if="$store.state.lang == 'fr'">Un appartement de charme et de standing situé à Port en Bessin, au cœur des plages du débarquement en Normandie.</span><span v-if="$store.state.lang == 'us'">A charming and luxury apartment located in Port en Bessin, in the heart of the landing beaches in Normandy.</span> <br><br>
              </p>
            </div>
            <router-link to="/souslesembruns" class="btn btn-perso"><span v-if="$store.state.lang == 'fr'">Découvrir !</span><span v-if="$store.state.lang == 'us'">Discover !</span></router-link>
          </div>
        </div>
        <div class="case">
          <img src="../assets/apt2/redefgris .jpg" alt="">
        </div>
      </div>

      <div class="ligne">
        <div class="case">
          <img src="../assets/apt3/a3.jpg" alt="">
        </div>
        <div class="case">
          <div class="conteneur">
            <div class="gite">
              <h2>L'Entre Deux Phares</h2>
              <p class="prix"><span v-if="$store.state.lang == 'fr'">À partir de 93€/nuit</span><span v-if="$store.state.lang == 'us'">From 93€/night</span></p>
              <p class="description">
                <span v-if="$store.state.lang == 'fr'">L'Entre Deux Phares a ouvert ses portes en mai 2016, après une rénovation complète. Typique des immeubles de cette époque, il s'étend sur trois niveaux avec une vue magnifique sur la mer et ses marées.</span><span v-if="$store.state.lang == 'us'">L'Entre Deux Phares opened its doors in May 2016, after a complete renovation. Typical of buildings from this period, it spans three levels with a magnificent view of the sea and its tides.</span> <br><br>
              </p>
            </div>
            <router-link to="/lentredeuxphares" class="btn btn-perso"><span v-if="$store.state.lang == 'fr'">Découvrir !</span><span v-if="$store.state.lang == 'us'">Discover !</span></router-link>
          </div>
        </div>
      </div>

      <div class="ligne inverse">
        <div class="case">
          <div class="conteneur">
            <div class="gite">
              <h2>La Capitainerie n°1</h2>
              <p class="prix"><span v-if="$store.state.lang == 'fr'">À partir de 86€/nuit</span><span v-if="$store.state.lang == 'us'">From 86€/night</span></p>
              <p class="description">
                <span v-if="$store.state.lang == 'fr'">Situé à Port en Bessin, en Normandie, au cœur des plages du débarquements, "La Capitainerie" est un immeuble de charme avec une vue exceptionnelle sur le port et la mer.</span><span v-if="$store.state.lang == 'us'">Located in Port en Bessin, in Normandy, in the heart of the landing beaches, "La Capitainerie" is a charming building with an exceptional view of the port and the sea. </span> <br><br>
              </p>
            </div>
            <router-link to="/lacapitainerie" class="btn btn-perso"><span v-if="$store.state.lang == 'fr'">Découvrir !</span><span v-if="$store.state.lang == 'us'">Discover !</span></router-link>
          </div>
        </div>
        <div class="case">
          <img src="../assets/apt4/redefgris.jpg" alt="">
        </div>
      </div>

      <div class="ligne">
        <div class="case">
          <img src="../assets/apt1/redefgris.jpg" alt="">
        </div>
        <div class="case">
          <div class="conteneur">
            <div class="gite">
              <h2>La Capitainerie n°2</h2>
              <p class="prix"><span v-if="$store.state.lang == 'fr'">À partir de 86€/nuit</span><span v-if="$store.state.lang == 'us'">From 86€/night</span></p>
              <p class="description">
                <span v-if="$store.state.lang == 'fr'">Situé à Port en Bessin, en Normandie, au cœur des plages du débarquements, "La Capitainerie" est un immeuble de charme avec une vue exceptionnelle sur le port et la mer.</span><span v-if="$store.state.lang == 'us'">Located in Port en Bessin, in Normandy, in the heart of the landing beaches, "La Capitainerie" is a charming building with an exceptional view of the port and the sea. </span> <br><br>
              </p>
            </div>
            <router-link to="/lacapitainerie2" class="btn btn-perso"><span v-if="$store.state.lang == 'fr'">Découvrir !</span><span v-if="$store.state.lang == 'us'">Discover !</span></router-link>
          </div>
        </div>
      </div>

      <div class="ligne inverse">
        <div class="case">
          <div class="conteneur">
            <div class="gite">
              <h2>Le Bois Flotté</h2>
              <p class="prix"><span v-if="$store.state.lang == 'fr'">À partir de 130€/nuit</span><span v-if="$store.state.lang == 'us'">From 130€/night</span></p>
              <p class="description">
                <span v-if="$store.state.lang == 'fr'">Au cœur de Port-en-Bessin, cette location 4 <i class="fas fa-star"></i> offre l'authenticité de la maison ancienne aux murs en pierres apparentes. La décoration soignée y mêle l'ancien, le moderne dans l'esprit bois flotté et ambiances maritimes.</span><span v-if="$store.state.lang == 'us'">In the heart of Port-en-Bessin, this 4 <i class="fas fa-star"></i> rental offers the authenticity of an old house with exposed stone walls. The careful decoration combines the old and the modern in the spirit of driftwood and maritime atmospheres.</span> <br><br>
              </p>
            </div>
            <router-link to="/leboisflotte" class="btn btn-perso"><span v-if="$store.state.lang == 'fr'">Découvrir !</span><span v-if="$store.state.lang == 'us'">Discover !</span></router-link>
          </div>
        </div>
        <div class="case">
          <img src="../assets/apt6/gris.jpg" alt="">
        </div>
      </div>

      <div class="ligne">
        <div class="case">
          <img src="../assets/apt7/7.jpg" alt="">
        </div>
        <div class="case">
          <div class="conteneur">
            <div class="gite">
              <h2>La maison d'Harold</h2>
              <p class="prix"><span v-if="$store.state.lang == 'fr'">À partir de 150€/nuit</span><span v-if="$store.state.lang == 'us'">From 150€/night</span></p>
              <p class="description">
                <span v-if="$store.state.lang == 'fr'">Nouveauté juillet 2022, au cœur de Port-en-Bessin à 50 m du port, maison entièrement rénovée avec une vue magnifique sur le port et la tour Vauban classée au monument historique.</span><span v-if="$store.state.lang == 'us'">New in July 2022, in the heart of Port-en-Bessin 50m from the port, completely renovated house with a magnificent view of the port and the Vauban tower listed as a historical monument.</span> <br><br>
              </p>
            </div>
            <router-link to="/lamaisondharold" class="btn btn-perso"><span v-if="$store.state.lang == 'fr'">Découvrir !</span><span v-if="$store.state.lang == 'us'">Discover !</span></router-link>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<style scoped>
  .slide{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  .voileGris{
    width: 100%;
    height: 100vh;
    background-color: #848b9a54;
    position: absolute;
    top:0;
    text-align: center;
  }

  .logo{
    position: absolute;
    width: 290px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: flou 2s linear;
  }

  .stars{
    position: absolute;
    height: 30px;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: flou 2s linear 1s backwards;
  }

 .voileGris h1 {
   color: #fff;
   font-size: 96px;
   font-family: 'Kaushan Script', cursive;
   background: linear-gradient(90deg, #35353500 40%, #fff 50%, #35353500 60%);
   background-size: auto auto;
   background-clip: border-box;
   background-size: 250% auto;
   background-clip: text;
   /*text-fill-color: transparent;*/
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation: textclip 5s linear 2s backwards, textclip2 2s linear 5.4s forwards;
   display: inline-block;
   position: absolute;
   top: 75%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.fa-chevron-circle-down{
  position: absolute;
  color: #fff;
  font-size: 60px;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  animation: clignote 1s linear infinite alternate;
}

.presentations{
  margin: 80px auto;
}

.presentations h2{
  margin-bottom: 30px;
}

.listeGite{
  width: 100%;
}

.ligne{
  width: 100%;
  display: flex;
  flex-direction:row;
}

.case{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listeGite img{
  width: 100%;
}

.gite{
  color:#6b717e;
  width: 70%;
  margin: 30px auto;
  vertical-align: middle;
  border-left: #6b717e 1px solid;
  padding-left: 10px;
}

.gite h2{
  font-size: 50px;
}

.gite .prix{
  font-size: 18px;
  margin-left: 15px;
}

.gite p{
  font-family: "raleway";
  margin: 25px auto;
}

.btn-perso{
  color: #fff;
  background-color: #5C616B;
  border-radius: 0;
  margin-top: 30px;
  margin-left: 20%;
}

.btn-perso:hover{
  color: #fff;
  background-color: #848B9A;
}

.warn{
  text-align: center;
  width: 60%;
  margin: 0 auto 80px auto;
  padding: 20px;
  background-color: rgb(235, 235, 235);
  border: 2px solid rgb(148, 148, 148);
  border-radius: 10px;
}

.warn h3{
  font-family: 'Kaushan Script', cursive;
}

.warn hr{
  width: 70%;
  border: none;
  height: 2px;
  background-color: rgb(148, 148, 148);
}

@media screen and (max-width: 1600px) {
  .voileGris h1{
    font-size: 65px;
  }
}

@media screen and (max-width: 1250px) {
  .ligne{
    flex-direction: column;
  }
  .ligne.inverse{
    flex-direction: column-reverse;
  }
  .case{
    width: 100%;
  }
  .btn-perso{
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .logo{
    top: 35%;
    width: 200px;
  }

  .stars{
    top: 55%;
  }

  .voileGris h1{
    font-size: 48px;
  }

  .fa-chevron-circle-down{
    font-size: 48px;
  }
}

@keyframes textclip {
  to {
    background-position: -250% center;
  }
}

@keyframes textclip2 {
  from{
    /*text-fill-color: white;*/
    -webkit-text-fill-color: #fff;
    opacity: 0;
  }
  to {
    -webkit-text-fill-color: #fff;
    opacity: 1;
  }
}

@keyframes flou {
  0% {
    filter: blur(100px);
  }
  100% {
    filter: blur(0);
  }
}

@keyframes clignote {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script>

export default {
  name: 'Home',
  components: {
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
